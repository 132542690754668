// eslint-disable-next-line gamut/no-css-standalone
import '@splidejs/splide/dist/css/splide.min.css';

import { Box, BoxProps, FlexBox } from '@codecademy/gamut';
import {
  MiniChevronLeftIcon,
  MiniChevronRightIcon,
} from '@codecademy/gamut-icons';
import { CheckerDense } from '@codecademy/gamut-patterns';
import { breakpoints } from '@codecademy/gamut-styles';
import { UserClickData } from '@codecademy/tracking';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import {
  Options,
  Splide,
  SplideSlide as UnstyledSplideSlide,
  SplideTrack as UnstyledSplideTrack,
} from '@splidejs/react-splide';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ArrowButton } from './elements';

type Breakpoint = keyof typeof breakpoints;

type CarouselProps = {
  items: JSX.Element[];
  onArrowClick?: (props: UserClickData) => void;
  options?: Options;
  patternedBackground?: boolean;
  leftPadding?: boolean;
  showArrowDisplay?: Record<Breakpoint, boolean>;
  title?: JSX.Element;
  variant?: 'catalog_home' | 'catalog_hub';
};

const CarouselWrapper = styled(Box)`
  transform: translateX(-1rem);
  width: calc(100% + 2rem);
`;

const SplideTrack = styled(UnstyledSplideTrack, {
  shouldForwardProp: isPropValid,
})`
  padding-top: ${(props: { patternedBackground?: boolean }) =>
    props.patternedBackground ? '1rem' : '0'};
  padding-bottom: ${(props: { patternedBackground?: boolean }) =>
    props.patternedBackground ? '2rem' : '0'};
`;

const SplideSlide = styled(UnstyledSplideSlide, {
  shouldForwardProp: isPropValid,
})`
  display: flex;
  flex-shrink: ${(props: { noShrink?: boolean }) =>
    props.noShrink ? 'initial' : 0};
`;

const defaultOptions = {
  gap: '2rem',
  perPage: 3,
  pagination: false,
  keyboard: false,
  slideFocus: false,
  arrows: false,
  perMove: 2,
  padding: '1rem',
  breakpoints: {
    [parseInt(breakpoints.md, 10)]: {
      perPage: 2,
      perMove: 1,
    },
  },
};

const StyledBox = styled(FlexBox)`
  list-style-type: none;
`;

export const PatternedContainer: React.FC<
  React.PropsWithChildren<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: React.ElementType<any>;
    bottomPadding?: boolean;
    leftPadding?: boolean;
    overflowAuto?: boolean;
  }>
> = ({ as, children, bottomPadding, leftPadding, overflowAuto }) => {
  return (
    <Box position="relative" py={24} px={8}>
      <Box
        borderRadius="lg"
        overflow="hidden"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        width="100%"
        zIndex={0}
      >
        <CheckerDense />
      </Box>
      <StyledBox
        as={as}
        columnGap={64}
        position="relative"
        overflowX={overflowAuto ? 'auto' : 'hidden'}
        pt={16}
        pb={bottomPadding ? 24 : 0}
        pl={{ xl: leftPadding ? 16 : 0 }}
        m={0}
        zIndex={1}
      >
        {children}
      </StyledBox>
    </Box>
  );
};

export const Carousel: React.FC<CarouselProps> = ({
  items,
  leftPadding = false,
  onArrowClick,
  options: optionsProp = {},
  patternedBackground = false,
  showArrowDisplay,
  title,
  variant = 'catalog_home',
}) => {
  const ref = useRef<Splide | null>(null);
  const [index, setIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState<number | null>();

  // required in the case where items conditionally update (i.e. loading cards to actual cards)
  useEffect(() => {
    if (ref.current?.splide?.options.perPage) {
      setLastIndex(items.length - ref.current.splide.options.perPage);
    } else {
      setLastIndex(items.length);
    }
  }, [items, ref.current?.splide?.options.perPage]);

  const options = useMemo(() => {
    return {
      ...defaultOptions,
      ...optionsProp,
    };
  }, [optionsProp]);

  const arrowDisplay: BoxProps['display'] = useMemo(() => {
    switch (items.length) {
      case 1:
      case 2:
        return 'none';
      default:
        return 'flex';
    }
  }, [items]);

  const onClickForward = () => {
    onArrowClick?.({ target: 'forward_arrow' });
    ref.current?.go('>');
  };
  const onClickBack = () => {
    onArrowClick?.({ target: 'backward_arrow' });
    ref.current?.go('<');
  };
  const determineLastIndex = (perPage: number) => {
    setLastIndex(items.length - perPage);
  };

  const isCatalogHubVariant = variant === 'catalog_hub';

  const CarouselContent = (
    <Splide
      onMove={({ index }) => setIndex(index)}
      onUpdated={(splide) => {
        if (splide.options.perPage) determineLastIndex(splide.options.perPage);
      }}
      onMounted={(splide) => {
        if (splide.options.perPage) determineLastIndex(splide.options.perPage);
        if (splide.options.autoplay) {
          splide.Components.Autoplay.play();
        }
      }}
      hasTrack={false}
      ref={ref}
      options={{ ...options }}
    >
      <SplideTrack patternedBackground={patternedBackground}>
        {items.map((item, index) => (
          <SplideSlide
            noShrink={items.length === 1}
            // index should be ok since order should not change
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {item}
          </SplideSlide>
        ))}
      </SplideTrack>
    </Splide>
  );

  const shouldDisplayArrow = (
    showArrowDisplay?: Record<Breakpoint, boolean>
  ) => {
    if (showArrowDisplay) {
      const displayProps = { _: '', xs: '', sm: '', md: '', lg: '', xl: '' };
      Object.keys(displayProps).forEach((screensize: Breakpoint) => {
        displayProps[screensize] =
          isCatalogHubVariant || showArrowDisplay?.[screensize]
            ? 'flex'
            : arrowDisplay;
      });
      return displayProps;
    }

    return isCatalogHubVariant ? 'flex' : arrowDisplay;
  };

  return (
    <>
      <FlexBox
        justifyContent={{
          _: title ? 'space-between' : isCatalogHubVariant ? 'start' : 'end',
          md: title ? 'space-between' : 'end',
        }}
        pb={8}
        alignItems="baseline"
      >
        {title ? (
          <FlexBox pb={patternedBackground ? 8 : 0}>{title}</FlexBox>
        ) : null}
        <Box display={shouldDisplayArrow(showArrowDisplay)} pr={0}>
          <ArrowButton
            disabled={index === 0}
            icon={MiniChevronLeftIcon}
            mr={8}
            onClick={onClickBack}
            px={4}
            tip="Scroll backward"
            tipProps={{ placement: 'floating' }}
          />
          <ArrowButton
            disabled={index === lastIndex}
            icon={MiniChevronRightIcon}
            onClick={onClickForward}
            px={4}
            tip="Scroll forward"
            tipProps={{ placement: 'floating' }}
          />
        </Box>
      </FlexBox>
      {patternedBackground ? (
        <PatternedContainer leftPadding={leftPadding}>
          <CarouselWrapper>{CarouselContent}</CarouselWrapper>
        </PatternedContainer>
      ) : (
        <CarouselWrapper>{CarouselContent}</CarouselWrapper>
      )}
    </>
  );
};
